import { AppBar, Button, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import Logo from '../remax-logo.png'
import { useNavigate } from 'react-router-dom';

const Navbar = ({ logged_in }) => {
  const navigate = useNavigate()

  const onLogoClick = () => {
    console.log("CLICKED")
    navigate('/')
  }

    return (
      <AppBar sx={{ bgcolor: "white" }} position="static">
        <Toolbar sx={{ bgcolor: "#de1827" }} style={{ minHeight: "3px"}}/>
        <Toolbar>
          <Box
            component="img"
            sx={{
            height: 64,
            }}
            alt="logo"
            src={Logo}
            onClick={onLogoClick}
          />
          <Box sx={{ flex: 1 }}/>
          <Button sx={{ bgcolor: "white", color: "#de1827"}} style={{ fontWeight: "700" }} href='/login'>{logged_in ? "Agent Logout" : "Agent Login"}</Button>
        </Toolbar>
      </AppBar>
    )
}

export default Navbar