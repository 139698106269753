import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import OpenHouse from './components/OpenHouse'
import Login from './components/Login'
import Home from './components/Home'


const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route exact path='/dashboard' element={<Dashboard/>}/>
        <Route exact path='/login' element={<Login/>}/>
      </Routes>
    </Router>
  )
}

export default App