import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import axios from 'axios'

const DisplayOptions = ({ address, id }) => {
  const [displayOption, setDisplayOption] = useState("openhouse");

  const handleChange = (event) => {
    const option = event.target.value;
    setDisplayOption(event.target.value);
    console.log(option)
    const { data } = axios.post('https://nft-validation.uc.r.appspot.com/api/dashboard/form/setdisplay', { option, address })
  };

  useEffect(() => {
    const getDisplay = async () => {
      const { data } = await axios.post('https://nft-validation.uc.r.appspot.com/api/dashboard/form/getdisplay', { id })
      setDisplayOption(data.data.option)
    }

    getDisplay()
  }, [])


  return (
    <Box sx={{ maxWidth: 180 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Display Options</InputLabel>
        <Select
          sx={{minWidth: 180}}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={displayOption}
          label="Display Options"
          onChange={handleChange}
        >
          <MenuItem value={"openhouse"}>Open House Form</MenuItem>
          <MenuItem value={"webredirect"}>Web Redirect</MenuItem>
          <MenuItem value={"flyer"}>Flyer</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default DisplayOptions