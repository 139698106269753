import { useEffect, useState } from "react"
import OpenHouse from "./OpenHouse"
import axios from 'axios'
import { Document, Page } from 'react-pdf';
import flyer from '../flyer.pdf'

// axios.defaults.withCredentials = true

const Home = () => {
    const queryParameters = new URLSearchParams(window.location.search)
    let id = queryParameters.get("id")

    if (!id) {
        id = 1
    }

    const [display, setDisplay] = useState("openhouse")
    const [url, setURL] = useState("")
    const [address, setAddress] = useState("")

    useEffect(() => {
        const getDisplay = async () => {

            console.log({ id })
            const { data } = await axios.post("https://nft-validation.uc.r.appspot.com/api/dashboard/form/getdisplay", { id })
            console.log(data)
            setAddress(data.data.address)
            setURL(data.data.url)
            setDisplay(data.data.option)
        }
        getDisplay()
    }, [])

    const whatToDisplay = () => {
        if (display === 'openhouse') {
            return <OpenHouse house={address} id={id}/>
        } else if (display === 'webredirect') {
            window.location.href = url;
        } else if (display === 'flyer') {
            window.location.href = flyer;
        }
    }

    return (
        <>
        {whatToDisplay()}
        {/* <OpenHouse house={address}/> */}
        </>
    )
}

export default Home