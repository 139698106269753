import { Box, Grid, Switch, Typography } from "@mui/material"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Navbar from "./Navbar"
import { useState } from "react";
import AddressOptions from "./AddressOptions";


const Dashboard = () => {

    return (
        <>
            <Navbar logged_in={true}/>
            <Box sx={{marginTop: 2, marginLeft: 3.5, marginRight: 3.5}}>
                <Grid container spacing={2}>
                    <Grid item>
                        <AddressOptions address={"5523 S INDIANA AVE #1"} city={"Chicago"} state={"IL"} zip={60637} id={1}/>
                    </Grid>
                    <Grid item>
                        <AddressOptions address={"7228 S COLES AVE"} city={"Chicago"} state={"IL"} zip={60649} id={2}/>
                    </Grid>
                    <Grid item>
                        <AddressOptions address={"2524 W 45TH PL"} city={"Chicago"} state={"IL"} zip={60632} id={3}/>
                    </Grid>
                    <Grid item>
                        <AddressOptions address={"9405 S LOOMIS ST"} city={"Chicago"} state={"IL"} zip={60620} id={4}/>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
} 

export default Dashboard