import { Box, Container, CssBaseline, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react';
import Navbar from './Navbar';
import jeffHouse from "../jeffhouse.jpg"
import fortyfifth from '../houses/45th.jpeg'
import coles from '../houses/coles.jpeg'
import indiana from '../houses/indiana.jpeg'
import loomis from '../houses/loomis.jpeg'

const OpenHouse = ({ house, id }) => {

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("9770 SE Eola Hills Road")

  const handleSubmit = () => {

  }

  const houseToDisplay = () => {
    console.log(id, house)
    if (id == 1) {
      return <img src={indiana} width="300"/>
    } else if (id == 2) {
      return <img src={loomis} width="300"/>
    } else if (id == 3) {
      return <img src={fortyfifth} width="300"/>
    } else {
      return <img src={coles} width="300"/>
    }
  }

  return (
    <div className="App">
      <Navbar/>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h4" sx={{ fontWeight: "575", color: "#003aa8", fontSize: "30px" }}>
            OPEN HOUSE SIGN IN
          </Typography>
          <Typography component="h1" variant="h6" sx={{ fontWeight: "500" }}>
            {house}
          </Typography>
          {houseToDisplay()}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="phone"
              label="Phone Number"
              type="phone"
              id="phone"
              autoComplete="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: "#003aa8" }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default OpenHouse;
