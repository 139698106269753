import { Box, Card, CardActions, CardContent, FormControlLabel, FormGroup, Grid, Switch, Typography } from "@mui/material"
import DisplayOptions from "./DisplayOptions"
import { useEffect } from "react"



const AddressOptions = ({ address, city, state, zip, id }) => {

    return (
        <>
        <Card variant="outlined" sx={{minWidth: 350, maxWidth: 350, display: "inline-block", boxShadow: 3}}>
            <CardContent sx={{alignItems: "center"}}>
                <Typography variant="h6" sx={{ fontWeight: 550 }}>{address}</Typography>
                <Typography variant="h6" sx={{ fontSize: 15 }}>{city}, {state} {zip}</Typography>
            </CardContent>
            <CardActions>
                <DisplayOptions address={address} id={id}/>
                <FormGroup>
                    <FormControlLabel control={<Switch defaultChecked />} label="GeoFence" />
                </FormGroup>
            </CardActions>
        </Card>
        </>
    )
}

export default AddressOptions